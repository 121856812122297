import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Nav, PrivateRoute } from 'components';
import { history } from 'utils';
import { Home } from 'home';
import { Login } from 'login';
import { Verify } from 'verify';

export { App };

function App() {
    return (
        <div className="app-container bg-light">
            <Router history={history}>
                <Nav />
                <div className="container pt-4 pb-4">
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <Route path="/login" component={Login} />
                        <Route path="/verify" component={Verify} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
            </Router>
        </div>
    );
}
