import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";
import { authAtom } from "state";
import { useUserActions } from "actions";
import { Link } from "react-router-dom";
export { Verify };

function Verify({ history }) {
	const auth = useRecoilValue(authAtom);
	const userActions = useUserActions();
	let [showReloginLink, setShowReloginLink] = useState(false);

	useEffect(() => {
		// redirect to home if already logged in
		if (auth) history.push("/");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const codeRegExp = /^[1-9]\d{5}$/; // 6 digits

	// form validation rules
	const validationSchema = Yup.object().shape({
		verificationCode: Yup.string()
			.required("Verification code is required")
			.matches(codeRegExp, "Verification code is not valid"),
	});
	const formOptions = { resolver: yupResolver(validationSchema) };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, setError, formState } = useForm(formOptions);
	const { errors, isSubmitting } = formState;

	function onSubmit({ verificationCode }) {
		return userActions.verify(verificationCode)
			.then(() => history.push("/"))
			.catch((error) => {
				setShowReloginLink(true);
				setError("apiError", { message: error });
			});
	}

	return (
		<div className="col-md-6 offset-md-3 mt-5">
			<div className="card">
				<h4 className="card-header">Verification</h4>
				<div className="card-body">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							<label>SMS Verification Code</label>
							<input
								name="verificationCode"
								type="text"
								{...register("verificationCode")}
								className={`form-control ${errors.verificationCode ? "is-invalid" : ""}`}
							/>
							<div className="invalid-feedback">{errors.verificationCode?.message}</div>
						</div>
						<button disabled={isSubmitting} className="btn btn-primary">
							{isSubmitting && (
								<span className="spinner-border spinner-border-sm mr-1"></span>
							)}
							Next
						</button>
						{errors.apiError && (
							<div className="alert alert-danger mt-3 mb-0">
								{errors.apiError?.message} {showReloginLink ? <Link to="/login">Try again</Link> : null}
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}
