import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { entitiesAtom } from 'state';
import { useUserActions } from 'actions';
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function Home() {
  const entities = useRecoilValue(entitiesAtom);
  const userActions = useUserActions();
  const [tableActionError, setTableActionError] = useState(null);
  const [tableActionSucess, setTableActionSucess] = useState(null);

  useEffect(() => {
    userActions.getAllEntities();
  }, []);


  const addEntity = (entity) => {
    setTableActionError(null);
    setTableActionSucess(null);
    return userActions.addEntity(entity).then(() => {
      setTableActionSucess("Successfully added entity");
      userActions.getAllEntities();
    }).catch((error) => {
      setTableActionError(error);
    });
  }
  const updateEntity = (entity) => {
    setTableActionError(null);
    setTableActionSucess(null);
    userActions.updateEntity(entity).then(() =>
      userActions.getAllEntities()).catch(err => {
        setTableActionError("Failed to update entity")
      })
  };

  const deleteEntity = (entityId) => {
    setTableActionError(null);
    setTableActionSucess(null);
    userActions.deleteEntity(entityId).then(() =>
      userActions.getAllEntities()).catch(err => setTableActionError("Failed to delete entity"));
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      {tableActionError &&
        <div className="alert alert-danger mt-2 mb-2">
          {tableActionError}
        </div>
      }
      {tableActionSucess && (
        <div className="alert alert-success mt-2 mb-2">
          {tableActionSucess}
        </div>
      )}
      {(entities)
        ? <MaterialTable icons={tableIcons}
          editable={{
            isEditable: rowData => true,
            isDeletable: rowData => true,
            onRowAdd: newData =>
              new Promise(async (resolve, reject) => {
                await addEntity(newData);
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                delete newData["__v"];
                await updateEntity(newData);
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise(async (resolve, reject) => {
                await deleteEntity(oldData._id);
                resolve();
              })
          }}
          columns={entities.columns.map(o => ({ ...o }))}
          data={entities.data.map(o => ({ ...o }))}
          title="Entites"
          options={{ search: true, paging: false, filtering: false, exportButton: true }}
        />
        : <span className="spinner-border spinner-border-sm mr-1"></span>
      }
    </div>
  );
}

export { Home };