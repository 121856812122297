import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";
import { authAtom } from "state";
import { useUserActions } from "actions";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export { Login };

function Login({ history }) {
	const auth = useRecoilValue(authAtom);
	const userActions = useUserActions();

	useEffect(() => {
		// redirect to home if already logged in
		if (auth) history.push("/");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const phoneRegExp = /^\[1-9]\d{1,14}$/; // E.164 format

	// form validation rules
	const validationSchema = Yup.object().shape({
		phoneInput: Yup.string()
			.required("Phone number is required")
			// .matches(phoneRegExp, "Phone number is not valid"),
	});
	const formOptions = { resolver: yupResolver(validationSchema) };

	// get functions to build form with useForm() hook
	const { register, handleSubmit, setError, formState, control } = useForm(formOptions);
	const { errors, isSubmitting } = formState;

	function onSubmit(data) {
		const { phoneInput } = data
		return userActions.login(`+${phoneInput}`).then(() => history.push("/verify")).catch((error) => {
			setError("apiError", { message: error });
		});
	}

	return (
		<div className="col-md-6 offset-md-3 mt-5">
			<div className="card">
				<h4 className="card-header">FriendClub login</h4>
				<div className="card-body">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							<label>Phone Number</label>
							{/* <input
								name="phone"
								type="text"
								{...register("phone")}
								className={`form-control ${errors.phone ? "is-invalid" : ""}`}
							/> */}
							<Controller
								name="phoneInput"
								control={control}
								render={({ field }) => <PhoneInput
									country='il'
									{...field}
								/>}
							/>
						
							<div className="invalid-feedback">{errors.phone?.message}</div>
						</div>
						<button disabled={isSubmitting} className="btn btn-primary">
							{isSubmitting && (
								<span cslassName="spinner-border spinner-border-sm mr-1"></span>
							)}
							Login
						</button>
						{errors.apiError && (
							<div className="alert alert-danger mt-3 mb-0">
								{errors.apiError?.message}
							</div>
						)}
					</form>
				</div>
			</div>
{/* 
			<div className="card">
				<div className="card-body">
					lorem ipsum
				</div>
				<h4 className="card-header">about FriendClub</h4>

			</div> */}
		</div>
	);
}
